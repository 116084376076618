<template>
    <div></div>
</template>

<script>
export default {
    name: 'Token',
    beforeCreate() {
        window.location.href = '/token.pdf'
    }
}
</script>

<style>

</style>