<template>
  <div id="home">
    <div id="sideNavFader" @click="toggleSideMenu()" v-bind:class="{sideNavFaderActive: sideMenuActive}">
    </div>
    <div id="sideNav" class="side-menu" v-bind:class="{sideNavActive: sideMenuActive}">
      <div class="side-menu-header">
        <span></span><img src="./assets/header-logo-mob.svg"><img src="./assets/closebtn.svg" class="closebtn" @click="toggleSideMenu()">
      </div>
      <button><a href="#our-partners" @click="toggleSideMenu()">Our partners</a></button>
      <button><a href="#how-it-works" @click="toggleSideMenu()">How it works</a></button>
      <button><a href="https://mycelium.slite.page/p/obqIRb2fOKMB6A/FAQ" target="_blank">FAQs</a></button>
      <button><a href="http://whitepaper.uno.farm" target="_blank">Lightpaper</a></button>
      <button><a href="http://onepager.uno.farm" target="_blank">Onepager</a></button>
      <button><a href="https://mycelium.slite.page/p/65_OLpSNW9hUKE/UNO-Docs" target="_blank">Docs</a></button>
      <a href="http://app.uno.farm" class="sidemenu-enter-btn">Enter DApp</a>
    </div>
    <section id="header" class="header-section">
      <header>
        <nav>
          <img class="desktop-header-logo" src="./assets/header-logo.svg">
          <img class="mobile-header-logo" src="./assets/header-logo-mob.svg">
          <div class="desktop-header-nav-middle-btns">
            <a href="#how-it-works">How it works</a>
            <a href="#contact-us">Contact us</a>
            <a href="#our-partners">Our partners</a>
            <a href="https://mycelium.slite.page/p/obqIRb2fOKMB6A/FAQ" target="_blank">FAQs</a>
            <a href="http://whitepaper.uno.farm" target="_blank">Lightpaper</a>
            <a href="http://onepager.uno.farm" target="_blank">Onepager</a>
            <a href="https://mycelium.slite.page/p/65_OLpSNW9hUKE/UNO-Docs" target="_blank">Docs</a>
          </div>
          <a href="https://app.uno.farm" target="_blank" class="desktop-header-nav-btn">Enter DApp</a>
          <button class="mobile-header-sidemenu-button" @click="toggleSideMenu()"><img src="./assets/burger.svg"></button>
        </nav>
      </header>
    </section>
    <section class="mission-section">
      <div class="mission">
        <p class="mission_title">THE MOST POWERFUL<br>YIELD GENERATION<br>TOOL IN DEFI</p>
        <p class="mission_caption">Our mission is to bring together all the best yield sources in DeFi into one tool. To not only provide a unified interface but also to automate, verify and maximize your yield generation. To let you earn more and safer than you could ever do.</p>
        <a class="mission_btn" href="https://app.uno.farm" target="_blank">Enter DApp</a>
      </div>
      <div id="contact-us" class="socialmedia">
        <p class="socialmedia_title">FOLLOW US ON<br>SOCIAL MEDIA</p>
        <p class="socialmedia_caption">Don't be shy, lets talk and build<br>something great together</p>
        <div class="socialmedia_links">
          <a href="https://discord.gg/Fh2vDyDXFs" target="_blank"><img src="./assets/social/discord.png"></a>
          <a href="https://t.me/uno_en_chat" target="_blank"><img src="./assets/social/telegram.png"></a>
          <!--<a><img src="./assets/social/reddit.png"></a>-->
          <a href="https://instagram.com/uno_defi" target="_blank"><img src="./assets/social/instagram.png"></a>
          <a href="https://twitter.com/uno_farm" target="_blank"><img src="./assets/social/twitter.png"></a>
        </div>
      </div>
    </section>
    <section id="how-it-works" class="how-it-works-section">
      <div class="how-it-works">
        <p class="how-it-works-title">HOW IT WORKS</p>
        <div class="how-it-works-video-wrapper">
          <video class="how-it-works-video" controls>
            <source src="./assets/vid/uno-vid.mp4" type="video/mp4">
          </video>
        </div>
        <div class="how-it-works-steps">
          <div class="how-it-works-unit">
            <p class="how-it-works-unit-index">1</p>
            <p class="how-it-works-unit-title">Choose your pool</p>
            <p class="how-it-works-unit-caption">Choose a pool of those tokens you<br>trust or that you hold in your<br>wallet.</p>
          </div>
          <div class="how-it-works-unit">
            <p class="how-it-works-unit-index">2</p>
            <p class="how-it-works-unit-title">Deposit your crypto-assets</p>
            <p class="how-it-works-unit-caption">Just deposit and relax.<br>UNO will automatically allocate your<br>funds among the best DeFi<br>protocols and make sure to<br>maximize profits and minimize risks.</p>
          </div>
          <div class="how-it-works-unit">
            <p class="how-it-works-unit-index">3</p>
            <p class="how-it-works-unit-title">Let UNO put DeFi to work for you</p>
            <p class="how-it-works-unit-caption">Track how your portfolio is growing<br>with UNO's handy analytics.<br>Redeem back your funds and<br>interest at any time.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="our-partners" class="partners-section">
      <div class="partners">
        <p class="partners-title">BACKED BY</p>
        <div class="partners-grid">
          <div class="partners-grid-row">
            <a href="https://polygon.technology/" target="_blank"><img src="./assets/partners/polygon.png"></a>
            <a href="https://metis.io/" target="_blank"><img src="./assets/partners/metis.png"></a>
          </div>
          <div class="partners-grid-row">
            <a href="https://aurora.dev/" target="_blank" class="partner-aurora"><img src="./assets/partners/aurora.png"></a>
            <a href="https://www.tde.fi/" target="_blank"><img src="./assets/partners/tdefi.png"></a>
            <a href="https://chain.link/" target="_blank" class="partner-wintermute"><img src="./assets/partners/chainlink.png"></a>
          </div>
          <div class="partners-grid-row">
            <a href="https://debridge.finance/" target="_blank" class="partner-aurora"><img src="./assets/partners/debridge.png"></a>
            <a href="https://bitkeep.com/" target="_blank" class="partner-wintermute"><img src="./assets/partners/bitkeep.png"></a>
          </div>
        </div>
      </div>
    </section>
    <section class="subscribe-section">
      <div class="subscribe">
        <p class="subscribe-title">SUBSCRIBE TO<br>OUR NEWSLETTER</p>
        <div class="subscribe-button">
          <input v-model="mail" class="mail" type="mail" placeholder="Email"><button @click="subscribe()">Get updates</button>
        </div>
      </div>
    </section>
    <section class="footer-section">
      <div class="footer-wrapper">
        <footer>
          <div class="footer-left">
            <a class="footer-logo-link" href="#header"><img class="footer-logo" src="./assets/footer-logo.svg"></a>
            <div class="footer-left-bottom">
              <div class="footer-badge">
                  <a class="msq-badge" href="https://magic.store/app/unofarm"><img src="./assets/social-footer/Validated-by-MSQ.png"></a>
              </div>
              <div class="footer-icons">
                <a href="https://t.me/uno_en_chat" ><img src="./assets/social-footer/telegram.svg"></a>
                <a href="https://twitter.com/uno_farm"><img src="./assets/social-footer/twitter.svg"></a>
                <a href="https://discord.gg/Fh2vDyDXFs"><img src="./assets/social-footer/discord.svg"></a>
                <!--<a><img src="./assets/social-footer/reddit.svg"></a>-->
              </div>
              <p class="footer-copyright">© 2021, UNO.Farm Ltd</p>
            </div>
          </div>
          <div class="footer-right">
            <div class="footer-menu">
              <div class="footer-menu-col">
                <a class="footer-menu-col-title">Services</a>
                <a href="http://whitepaper.uno.farm" target="_blank" class="footer-menu-col-link">Lightpaper</a>
                <a href="http://onepager.uno.farm" target="_blank" class="footer-menu-col-link">Onepager</a>
                <!--<a class="footer-menu-col-link">Privacy policy</a>-->
                <a href="https://mycelium.slite.page/p/z9hFUdMfC6rwi0/Brand-book-and-guidelines" target="_blank" class="footer-menu-col-link">Brand assets</a>
              </div>
              <div class="footer-menu-col">
                <a class="footer-menu-col-title">Support</a>
                <a href="https://mycelium.slite.page/p/URy6ZHnnqHGuRV/Using-UNO-tutorials" target="_blank" class="footer-menu-col-link">Step-by-Step Guides</a>
                <a href="./token.pdf" class="footer-menu-col-link">Token</a>
                <!--<a class="footer-menu-col-link">Institutions</a>
                <a class="footer-menu-col-link">Partnerships</a>-->
              </div>
            </div>
            <a href="https://app.uno.farm/" class="footer-btn"><span>Enter DApp</span><img src="./assets/footer-vector.svg"></a>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  data() {
    return {
      sideMenuActive: false,
      mail: ''
    }
  },
  computed: {
    validateEmail: function () {
      return String(this.mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
  methods: {
    toggleSideMenu() {
      this.sideMenuActive = !this.sideMenuActive;
    },

    subscribe() {
      try {
        if (this.mail!='' && this.validateEmail) {
          console.log(this.validateEmail);
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*"},
            body: JSON.stringify({ mail: JSON.stringify(this.mail) })
          };
          return fetch("http://localhost:3001/subscribe", requestOptions);
        }
      }
      catch (err) {
        console.log(err);
      }
    }
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

p {
  margin-bottom: 0;
}

#app {
  background-color: rgb(9, 30, 56);
  background-image: url('./assets/bg_gradient.png'), url('./assets/bg_color.png');
  background-repeat: repeat-y, repeat;
  background-position: bottom, center;
  min-height: 100vh;
}

#sideNavFader {
  height: 100%;
  width: 00;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.31);
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s, width 0s;
  transition-delay: width 0s, opacity 1s;
}

#sideNav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background-image: url('./assets/bg_gradient.png'), url('./assets/bg_color.png');
  background-repeat: repeat-y, repeat;
  background-position: bottom, center;
  background-size: auto 100%;
  background-repeat: repeat-x;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#sideNav div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 34px;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 36px;
  padding-right: 25px;
  width: 100%;
}

#sideNav div img:first-child {
  height: 8px;
}

#sideNav div img:last-child {
  height: 18px;
}

#sideNav button, .sidemenu-enter-btn {
  background-color: transparent;
  border: 0;
  color: white;
  text-align: right;
  padding-right: 34px;
  margin-top: 14px;
  margin-bottom: 14px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  z-index: 6;
}

#sideNav button, #sideNav button a {
  width: 190px;
}

#sideNav button a, #sideNav button a:hover, #sideNav button a:visited {
  color: white;
  text-decoration: none;
}


.sidemenu-enter-btn {
  position: relative;
  padding: 11px 32px;
  background: #6BB39C !important;
  border-radius: 1000px;
  margin-right: 34px;
  color: white;
  width: 174px !important;
}

.sidemenu-enter-btn:hover, .sidemenu-enter-btn:visited {
  color: white;
}

.sidemenu-enter-btn:before {
  position: absolute;
  content: "";
  z-index: 5;
  background-image:url('./assets/gfx/mobile/sidemenu-palochki.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: -16px;
  top: -17px;
  width: 214px;
  height: 86px;
}

.sideNavActive {
  width: 86% !important;
  box-shadow: -24px 0px 9px 1px rgba(0, 0, 0, 0.62);
}

.sideNavFaderActive {
  width: 14% !important;
  opacity: 1 !important;
  transition: opacity 1.5s;
  transition-delay: opacity 1s;
}

.mobile-header-sidemenu-button {
  display: none;
  border: 0;
  background-color: transparent;
}

section {
  position: relative;
  text-align: center;
}

header {
  padding-top: 47px;
  padding-left: 54px;
  padding-right: 54px;
  position: relative;
  z-index: 6;
}

header nav {
  padding: 4px;
  max-width: 1816px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 1000px;
}

.desktop-header-logo {
  margin-left: 44px;
}

.mobile-header-logo {
  display: none;
}

.desktop-header-nav-middle-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.desktop-header-nav-middle-btns a {
  border: 0;
  background-color: transparent;
  color: black;
  padding: 14px 20px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 1000px;
  transition: background-color 0.4s, color 0.4s;
  cursor: pointer;
}

.desktop-header-nav-middle-btns a:hover {
  background-color: black !important;
  color: white !important;
  transition: background-color 0.4s, color 0.4s;
  text-decoration: none;
}

.desktop-header-nav-btn {
  box-sizing: border-box;
  padding: 18px 49.5px;
  border: 0;
  background-color: black;
  color: white;
  border-radius: 1000px;
  position: relative;
  border: 1px solid transparent;
  text-decoration: none;
}

.desktop-header-nav-btn:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  transition: background-color 0.2s, color 0.4s;
  text-decoration: none;
}

.desktop-header-nav-btn:visited {
  text-decoration: none;
}

.mission {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;
  padding-left: 80px;
  padding-right: 80px;
}

.mission_title {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 99.35%;
  color: #EED5C3;
  z-index: 6;
}

.mission_caption {
  position: relative;
  margin-top: 30px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: white;
  margin-bottom: 48px;
  z-index: 6;
  text-align: center;
  max-width: 35vw;
}

.mission_btn {
  position: relative;
  background-color: #419586;
  color: white;
  border: 0;
  padding: 10px 72px;
  border-radius: 48px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 34.352px;
  line-height: 99.35%;
  opacity: 0.85;
  transition: 0.2s;
  cursor: pointer;
  z-index: 6;
  animation: blink 4s infinite linear;
}

.mission_btn:hover {
  opacity: 1;
  transition: 0.2s;
  color: white;
  text-decoration: none;
}

.mission_btn:visited {
  color: white;
  text-decoration: none;
}

@keyframes blink {
    0% {
        background: #419586;
        box-shadow: 0px 0px 11px #419586;
    }
    25% {
        background: rgb(75, 160, 144);
        box-shadow: 0px 0px 13px rgb(75, 160, 144);
    }
    50% {
        background: rgb(67, 141, 126);
        box-shadow: 0px 0px 15px rgb(67, 141, 126);
    }
    75% {
        background: rgb(46, 116, 103);
        box-shadow: 0px 0px 13px rgb(46, 116, 103);
    }
    100% {
        background: #419586;
        box-shadow: 0px 0px 11px #419586;
    }
}

.socialmedia {
  padding-top: 320px;
}

.socialmedia_title {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 100%;
  color: #C46060;
  z-index: 6;
}

.socialmedia_caption {
  position: relative;
  margin-top: 10px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 130%;
  color: white;
  z-index: 6;
}

.socialmedia_links {
  position: relative;
  margin-top: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.socialmedia_links a {
  margin-left: 4px;
  margin-right: 4px;
  z-index: 6;
}

.socialmedia_links a img {
  cursor: pointer;
  opacity: 0.75;
  transition: 0.2s;
}

.socialmedia_links a img:hover {
  opacity: 1;
  transition: 0.2s;
}

.how-it-works {
  padding-top: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-it-works-title {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 100%;
  color: #C46060;
  z-index: 6;
}

.how-it-works-video-wrapper {
  background-image: url('./assets/gfx/video-frame.png'), url('./assets/gfx/video-placeholder.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%, 93% 93%;
  background-position: center;
  max-width: 928px;
  padding-top: 50px;
  padding-bottom: 54px;
  padding-left: 82px;
  padding-right: 80px;
  margin-top: 92px;
}

.how-it-works-video {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  z-index: 8;
}

.how-it-works-steps {
  margin-top: 80px;
  max-width: 1816px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.how-it-works-unit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 6;
}

.how-it-works-unit-index {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 65px;
  line-height: 100%;
  color: #C46060;
}

.how-it-works-unit-title {
  margin-top: 40px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  color: white;
}

.how-it-works-unit-caption {
  margin-top: 24px;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  color: white;
}

.partners {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners-title {
  position: relative;
  margin-top: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 100%;
  color: #C46060;
}

.partners-grid {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}

.partners-grid-row {
  margin-bottom: 50px;
}

.partners-grid-row a {
  position: relative;
  z-index: 6;
  display: inline-block;
  margin-right: 39px;
  margin-left: 39px;
}

.partners-grid-row a img {
  opacity: 0.75;
  transition: 0.3s;
}

.partners-grid-row a:hover img {
  opacity: 1;
  transition: 0.3s;
}

.partner-aurora {
  margin-left: 72px !important;
}

.partner-wintermute {
  margin-left: 96px !important;
  margin-right: 2px !important;
}

.subscribe {
  margin-top: 349px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribe-title {
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 100%;
  color: #C46060;
}

.subscribe-button {
  position: relative;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  background: #FFFFFF;
  border-radius: 1000px;
  padding: 4px 4px;
  z-index: 6;
}

.subscribe-button input {
  background: 0;
  border: 0;
  padding: 0 45px;
  margin: 0;
  float: left;
  width: 100%;
  overflow: hidden;
}

.subscribe-button input:focus {
  outline: none;
}

.subscribe-button button {
  border: 1px solid transparent;
  background: black;
  color: white;
  border-radius: 1000px;
  padding: 18px 45px;
  margin: 0;
  float: right;
  min-width: 186px;
  transition: 0.2s;
}

.subscribe-button button:hover {
  border: 1px solid black;
  background: white;
  color: black;
  transition: 0.2s;
}

.footer-wrapper {
  padding-left: 47px;
  padding-right: 47px;
}

footer {
  position: relative;
  margin: auto;
  margin-top: 500px;
  max-width: 1816px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.footer-left {
  z-index: 6;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-logo-link {
  margin-bottom: 342px;
}

/*.footer-logo {
  margin-bottom: 342px;
}*/
.msq-badge img{
  z-index: 6;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
  width:100%;
  max-width:100%;
  margin-left:-30px;
}
.footer-badge{
  width:100%;
  max-width:200px;
  margin-bottom:10px;
}
.footer-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-icons img {
  z-index: 6;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s;
}

.footer-icons img:hover {
  opacity: 1;
  transition: 0.2s;
}

.footer-copyright {
  margin-top: 50px;
  text-align: left;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer-menu {
  display: flex;
  flex-direction: row;
}

.footer-menu-col {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.footer-menu-col-title {
  margin-bottom: 28px;
  text-align: left;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: white;
  z-index: 6;
}

.footer-menu-col-title:hover {
  color: white;
  text-decoration: none;
}

.footer-menu-col-link {
  margin-bottom: 12px;
  text-align: left;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: white;
  cursor: pointer;
  opacity: 0.8;
  transition: 3s;
  z-index: 6;
}

.footer-menu-col-link:hover {
  color: #EED5C3;
  opacity: 1;
  transition: 0.2s;
  text-shadow: 2px 2px 2px #2c2724;
}

.footer-btn {
  margin-top: 87px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border: 1px solid transparent;
  background-color: #C46060;
  border-radius: 1000px;
  color: white;
  max-width: 328px;
  cursor: pointer;
  z-index: 6;
  opacity: 0.8;
  transition: 0.4s;
  text-decoration: none;
}

.footer-btn:hover {
  opacity: 1; 
  transition: 0.4s;
  border: 1px solid black;
}

.footer-btn:hover, .footer-btn:visited {
  text-decoration: none;
}

.footer-btn span {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: white;
  transition: 0.2s;
}

.footer-btn:hover span {
  text-shadow: 2px 2px 1px #2c2724;
  transition: 0.2s;
}

@media screen and (min-width: 1201px) {
  .header-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/header-smoke-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    top: 0;
    width: 409px;
    height: 265px;
  }

  .header-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/header-smoke-2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 0;
    right: 0;
    width: 676px;
    height: 408px;
  }

  .mission-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mission-group-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    top: -150px;
    width: 599px;
    height: 1387px;
  }

  .mission-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mission-group-2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    right: 0;
    top: -120px;
    width: 827px;
    height: 1433px;
  }

  .mission_btn:before {
    position: absolute;
    content: '';
    width: 400px;
    height: 99px;
    z-index: 50;
    left: -30px;
    top: -13px;
    background-image: url('./assets/palochki.png');
    background-size: 100% 100%;
  }

  /*.how-it-works-title:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/how-it-works-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 399px;
    height: 215px;
    left: -440px;
    top: -80px;
  }*/

  .how-it-works-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/how-it-works-smoke-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 560px;
    height: 731px;
    left: 0;
    bottom: -470px;
  }

  .how-it-works-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/how-it-works-smoke-2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 423px;
    height: 157px;
    right: 0;
    bottom: -140px;
  }

  .how-it-works:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image: url('./assets/gfx/how-it-works-video-group-1.png');
    background-size: 100% 100%;
    width: 214px;
    height: 298px;
    left: 0;
    bottom: 360px;
  }

  .partners-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/partners-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 832px;
    height: 1111px;
    left: 0;
    top: 180px;
  }

  .partners-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/partners-smoke-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 424px;
    height: 371px;
    right: 0;
    bottom: -180px;
  }

  .subscribe-title:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/subscribe-group-1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 237px;
    height: 219px;
    top: 12px;
    right: -210px;
  }

  .subscribe-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/subscribe-group-2.png');
    width: 244px;
    height: 283px;
    left: 0;
    bottom: -380px;
  }

  .subscribe-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/subscribe-smoke-1.png');
    width: 1194px;
    height: 1506px;
    right: 0;
    top: -270px;
  }

  .subscribe:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/subscribe-group-3.png');
    width: 480px;
    height: 476px;
    right: 0;
    top: -180px;;
  }

  .footer-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/footer-group-1.png');
    width: 426px;
    height: 544px;
    left: 0;
    bottom: 100px;
  }

  .footer-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/footer-smoke-1.png');
    width: 971px;
    height: 515px;
    right: 0;
    bottom: 0;
  }

  .footer-left:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/footer-group-2.png');
    width: 567px;
    height: 509px;
    left: 260px;
    top: -20px;
  }
}

@media screen and (max-width: 1200px) {
  .msq-badge img{
    margin-left: 0px;
    width:100%;
  }
  .footer-badge{
    width:125px;
    margin-bottom:3px;
  }

  .side-menu-header {
    position: relative;
    padding-left: 48px;
  }

  .side-menu-header:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/sidemenu-group-1.png');
    width: 132px;
    height: 350px;
    top: -26px;
    left: 0;
  }

  .side-menu:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/sidemenu-group-2.png');
    width: 328px;
    height: 384px;
    left: 0;
    bottom: 0;
  }

  .side-menu:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/sidemenu-group-3.png');
    width: 210px;
    height: 333px;
    right: 0;
    bottom: 0;
  }

  .header-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/header-smoke-1.png');
    width: 234px;
    height: 146px;
    top: 0;
    right: 60px;
  }

  .mission-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/mission-group-1.png');
    width: 254px;
    height: 617px;
    left: -14px;
    top: -180px;
  }

  .mission-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/mission-group-2.png');
    width: 288px;
    height: 519px;
    right: 0;
    top: -190px;
  }

  .socialmedia:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/social-media-1.png');
    width: 108px;
    height: 263px;
    left: 0;
    top: -60px;
  }

  .socialmedia:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/social-media-smoke-1.png');
    width: 219px;
    height: 168px;
    right: 0;
    top: 0px;
  }

  .socialmedia_links:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/social-media-smoke-2.png');
    width: 281px;
    height: 134px;
    left: 0;
    top: 60px;
  }

  .how-it-works-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/how-it-works-group-1.png');
    width: 111px;
    height: 367px;
    left: 0;
    bottom: 260px;
  }

  .how-it-works-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/how-it-works-group-2.png');
    width: 118px;
    height: 239px;
    right: 0;
    top: 360px;
  }

  .how-it-works:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/how-it-works-group-3.png');
    width: 118px;
    height: 178px;
    right: 0;
    bottom: 120px;
  }

  .partners-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/partners-group-1.png');
    width: 183px;
    height: 254px;
    left: 0;
    top: -20px;
  }

  .partners-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/partners-group-3.png');
    width: 209px;
    height: 194px;
    right: 0;
    top: 0;
  }

  .partners-title:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/partners-group-2.png');
    width: 122px;
    height: 86px;
    bottom: 30px;
  }

  .partners:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/partners-smoke-1.png');
    width: 374px;
    height: 434px;
    right: 0;
    top: 200px;
  }

  .subscribe-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/subscribe-group-1.png');
    width: 111px;
    height: 160px;
    left: 0;
    bottom: 10px;
  }

  .subscribe-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/subscribe-smoke-1.png');
    width: 320  px;
    height: 363px;
    left: 0;
    top: 80px;
  }

  .subscribe-button:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/subscribe-group-2.png');
    width: 65px;
    height: 50px;
    right: 0;
    top: 80px;
  }

  .footer-section:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/footer-group-1.png');
    width: 101px;
    height: 304px;
    left: -5px;
    top: 200px;
  }

  .footer-icons:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/footer-group-2.png');
    width: 28px;
    height: 28px;
    right: -20px;
    top: -45px;
  }

  .footer-section:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/footer-group-3.png');
    width: 150px;
    height: 282px;
    right: 0;
    top: 270px;
  }

  .subscribe:before {
    position: absolute;
    content: "";
    z-index: 5;
    background-image: url('./assets/gfx/mobile/footer-group-4.png');
    width: 334px;
    height: 362px;
    left: 0;
    top: 70px;
  }

  footer:after {
    position: absolute;
    content: "";
    z-index: 5;
    background-image:url('./assets/gfx/mobile/footer-smoke-1.png');
    width: 366px;
    height: 109px;
    right: -40px;
    bottom: 0;
  }

  header {
    padding-top: 26px;
    padding-left: 26px;
    padding-right: 18px;
  }

  header nav {
    background-color: transparent;
  }

  .desktop-header-logo {
    display: none;
  }

  .mobile-header-logo {
    display: block;
    min-height: 18px;
  }

  .desktop-header-nav-middle-btns {
    display: none;
  }

  .desktop-header-nav-btn {
    display: none;
  }

  .mobile-header-sidemenu-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .mission {
    z-index: 6;
  }

  .mission_title {
    font-size: 22px;
  }

  .mission_caption {
    font-size: 16px;
    max-width: 60vw;
  }

  .mission_btn {
    font-size: 15px;
    padding: 12px 64px;
  }

  .mission_btn:before {
    position: absolute;
    content: '';
    width: 248px;
    height: 52px;
    z-index: 50;
    left: -16px;
    top: -2px;
    background-image: url('./assets/palochki-mob.png');
    background-size: 100% 100%;
  }

  .socialmedia {
    padding-top: 88px;
    position: relative;
  }

  .socialmedia_title {
    font-size: 22px;
  }
  
  .socialmedia_caption {
    margin-top: 18px;
    font-size: 14px;
  }

  .socialmedia_links {
    margin-top: 29px;
  }

  .socialmedia_links a, .socialmedia_links a img{
    width: 56px;
    height: 56px;
    opacity: 1;
  }

  .how-it-works {
    margin-top: 0;
    padding-top: 120px;
  }

  .how-it-works-title {
    font-size: 22px;
  }

  .how-it-works-steps {
    margin-top: 40px;
    flex-direction: column;
    position: relative;
  }

  .how-it-works-unit {
    margin-bottom: 32px;
  }

  .how-it-works-unit-index {
    font-size: 50px;
  }

  .how-it-works-unit-title {
    margin-top: 10px;
    font-size: 18px;
  }

  .how-it-works-unit-caption {
    margin-top: 10px;
    font-size: 14px;
  }

  .partners-title {
    margin-top: 128px;
    font-size: 22px;
  }

  .partners-grid {
    margin-top: 56px;
  }

  .partners-grid-row {
    margin-bottom: 32px;
  }

  .partners-grid-row a {
    margin-left: 23px;
    margin-right: 23px;
  }

  .partners-grid-row a img {
    width: 130px;
    opacity: 1;
  }

  .partner-aurora {
    margin-left: 12px !important; 
  }

  .partner-wintermute {
    margin-left: 33px !important;
    margin-right: 16px !important;
  }

  .subscribe {
    margin-top: 181px;
  }

  .subscribe-title {
    font-size: 22px;
  }

  .subscribe-button {
    margin-top: 20px;
    width: auto;
    background: rgba(255, 255, 255, 0.61);
  }

  .subscribe-button input {
    padding: 0 22px;
    font-size: 14px;
  }

  .subscribe-button button {
    background: #378B7C;
    color: black;
    min-width: 76px; 
    font-size: 10px;
    padding: 4px 8px;
    white-space: nowrap;
  }

  footer {
    margin-top: 0;
    padding-top: 175px;
    flex-direction: column-reverse;
  }

  .footer-right {
    align-items: center;
  }

  .footer-menu {
    flex-direction: column;
  }

  .footer-menu-col {
    margin: 0;
    margin-bottom: 36px;
  }

  .footer-menu-col-title {
    text-align: center;
    font-size: 18px;
  }

  .footer-menu-col-link {
    text-align: center;
    font-size: 16px;
  }

  .footer-btn {
    margin-top: 10px;
    width: 208px;
    padding: 8.5px 24px;
  }

  .footer-btn span {
    font-size: 16px;
  }

  .footer-left {
    margin-top: 43px;
    align-items: center;
  }

  .footer-logo-link {
    margin-bottom: 12px;
  }

  .footer-logo {
    width: 99px;
    /*margin-bottom: 12px;*/
  }

  .footer-icons {
    justify-content: center;
    margin-bottom: 68px;
  }

  .footer-icons img {
    width: 32px;
    margin-left: 2.5px;
    margin-right: 2.5px;
    opacity: 1;
  }

  .footer-copyright {
    display: none;
  }
}

@media screen and (max-width: 830px) {
  .how-it-works-video-wrapper {
    background-image: url('./assets/gfx/mobile/video-frame.png'), url('./assets/gfx/video-placeholder.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 100%, 93% 93%;
    max-width: 320px;
    padding-top: 14px;
    padding-bottom: 8px;
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 32px;
  }
  .how-it-works-video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border-top-left-radius: 14px;
    border-top-right-radius: 10px;
  }
}

@media screen and (max-width: 600px) {
    .partners-grid-row a img {
        width: 75px;
    }
}

@media screen and (max-width: 370px) {
  .mission_btn {
    padding: 8px 40px;
    font-size: 12px;
  }

  .mission_btn:before {
    position: absolute;
    content: '';
    width: 172px;
    height: 46px;
    z-index: 50;
    left: -13px;
    top: -7px;
    background-image: url('./assets/palochki-mob.png');
    background-size: 100% 100%;
  }

  .partners-grid-row a {
    margin-left: 12px;
    margin-right: 12px;
  }

  .partner-aurora {
    margin-left: 6px !important;
  }

  .partner-wintermute {
    margin-left: 19px !important;
    margin-right: 14px !important;
  }
}

video::-webkit-media-controls-play-button {
  border-radius: 50%;
}
</style>
